import { Swiper, SwiperSlide } from "swiper/react";
import { Icon } from "@iconify/react";
import "swiper/css";
import { ThreeCircles } from "react-loader-spinner";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper";
import { useEffect, useState } from "react";

export default function Checklist({ request, baseURL }) {
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(0);
  const [cards, setCards] = useState([]);
  const [end, setEnd] = useState(false);
  const [init,setInit]=useState(0)

  useEffect(() => {
  }, [searching]);

  useEffect(() => {
  }, [init]);

  useEffect(() => {
  }, [cards]);

  useEffect(() => {
    buscar(page);
  }, [page]);

  useEffect(() => {
  }, [end]);

  function buscar(npage) {
    setSearching(true);
    let formData = new FormData();
    formData.append("pagina", npage);
    request(
      "POST",
      "checklist/get_post/",
      (response) => {
        if (response.status == 200) {
          setSearching(false);
          setCards([...cards, ...response.data]);
          if (response.data.length < 5) setEnd(true);
        }
      },
      (error) => {
        setSearching(false);
      },
      "Obteniendo datos",
      formData
    );
  }

  return (
    <div className="nunito">
      <div className="row">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          initialSlide={init}
          modules={[EffectCards]}
          className="mySwiper"
          onSlideChange={(swipe) => {
            if ((swipe.slides.length - swipe.activeIndex )< 4 && !end){
                setPage(page+1);
                setInit(swipe.activeIndex)
            }
          }}
        >
          {cards.map((card) => (
            <SwiperSlide>
              <div className="h100p relative">
                <img src={`${baseURL}${card.mini_img}`} alt="" width={"100%"} />
                <div className="row btn_download">
                  <a
                    href={`/uploads/contents/${card.slug}`}
                    target="_blank"
                    className="btn_download text_white row"
                  >
                    Descargar PDF
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
