import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function Last_blog({
  title,
  descripcion,
  date,
  autor,
  autor_img,
  slug,
  baseURL,
  mini_img,
  width,
}) {
  return (
    <div className="row margin30 gap30">
      <div className="row wrap">
        <div className="row_change title text_orange margintb15">
          Último blog
        </div>
        <Zoom>
          <div className="row_change subtitle text_orange tleft">
            {title}
          </div>
          <div className="row_change desc_blog margintb15">{descripcion}</div>
          <div className="row_change vcenter ">
            {width > 400 ? (
              <figure className="figure_autor_img margin10">
                <img
                  src={`${baseURL}${autor_img}`}
                  alt={autor}
                  className="autor_img"
                />
              </figure>
            ) : (
              ""
            )}

            <div className="row">
              <div className="row wrap">
                <div className="row_change autor_text tleft">Por {autor} </div>
                <div className="row_change nunito text_orange">{date}</div>
              </div>
              <div className="row_change right">
                <Link to={`/blogs/${slug}`} className="btn margintb15">
                  Leer más
                </Link>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
      {width > 768 ? (
        <div className="pad10">
          <Fade right>
            <img
              className="img_mini_blog"
              src={`${baseURL}${mini_img}`}
              alt={title}
            />
          </Fade>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Last_blog;
