import Base from "./Base";
import Banner from "./Snippets/Banner";
import "./../static/css/Casos.css";
import TextBoxContent from "./Snippets/TextBoxContent";
import onexo_ci_toks2 from "./../static/images/onexo_ci_toks2.webp";
import onexo_ci_ex1 from "./../static/images/onexo_ci_ex1.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import Bounce from "react-reveal/Bounce";
import MetaTags from 'react-meta-tags';
import Slide from "react-reveal/Slide";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Contactanos() {
  return (
    <section className="contactanos row wrap" id="casos">
      <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
      <div className="row">
        <Bounce>
          <div className="title">Nuevos caminos</div>
        </Bounce>
      </div>
      <div className="row mw600px margintb15">
        <Slide bottom>
          <div className="subtitle text_white">
            Si tu negocio está listo para ir al siguiente nivel, estamos listos
            para guiarte.
          </div>
        </Slide>
      </div>
      <div className="row margintb15">
        <Link to="/contacto" className="btn_servicio active margintb15">
          Contáctanos
        </Link>
      </div>
    </section>
  );
}

const Content = () => {
  const Encuestas = () => {
    return (
      <div className="row  wrap">
        <div className="exoB transp35 row jcleft">
          Diseño | Desarrollo | Soporte
        </div>
        <div className="exoB row jcleft padtb20">
          <span className="icon_casos text_white">
            <FontAwesomeIcon icon={faMobileScreen} />
          </span>{" "}
          Aplicación móvil
        </div>
        <div className="row mw400px tetx_normal">
          Una poderosa herramienta digital que está marcando la diferencia en la
          mejora constante del servicio a cliente en restaurantes.
        </div>
        <div className="row jcleft">
          <Link to="/nosotros" className="btn margintb15">
            Leer más
          </Link>
        </div>
      </div>
    );
  };

  const Fintech = () => {
    return (
      <div className="row  wrap">
        <div className="exoB transp35 row jcleft">
          Diseño | Desarrollo | Soporte
        </div>
        <div className="exoB row jcleft padtb20">
          <span className="icon_casos text_white">
            <FontAwesomeIcon icon={faMobileScreen} />
          </span>{" "}
          Aplicación móvil
        </div>
        <div className="row mw400px tetx_normal">
          ¿Habías que pensado que podrías descansar de verdad mientras tus
          inversiones permanecen a salvo? Una app móvil que cambiará al mundo.
        </div>
        <div className="row jcleft">
          <Link to="/nosotros" className="btn margintb15">
            Leer más
          </Link>
        </div>
      </div>
    );
  };

  return (
    <section className="content">
      <Banner name={"casos"} text={"Casos de éxito"} />
      <div className="row wrap padtb50">
        <TextBoxContent
          title={"Encuestas Toks"}
          Content={Encuestas}
          image={onexo_ci_toks2}
          orientation={"right"}
          color={"orange"}
        />
      </div>
      <div className="row wrap padtb50">
        <TextBoxContent
          title={"Fintech"}
          Content={Fintech}
          image={onexo_ci_ex1}
          orientation={"left"}
          color={"black"}
        />
      </div>
      <div className="row wrap padtb50">
        <Contactanos />
      </div>
    </section>
  );
};

export default function Home() {
  return <Base Content={Content} source={"Casos"} />;
}
