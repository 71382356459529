import onexo_header from "../../static/images/onexo_header.png"
import log from "../../static/images/log.svg"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
function Header({close_open,source}) {
    return(<header className="header row padlr20" id="header">
        <div className="row mw200px">
        <Link to="/" className="row" ><img src={log} className="logo" alt="Onexo" title="logo" /></Link>
           
        </div>
        <div className="navbar">
            <ul className="row items_menu">
                <li className="marlr5 row"><Link to="/" className={source=="Home" ? "exoR item_menu active" : "exoR item_menu "} >Inicio</Link></li>
                <li className="marlr5 row"><Link to="/servicios" className={source=="Servicios" ? "exoR item_menu active" : "exoR item_menu "} >Servicios</Link></li>
                <li className="marlr5 row"><Link to="/nosotros" className={source=="Nosotros" ? "exoR item_menu active" : "exoR item_menu "} >Nosotros</Link></li>
                <li className="marlr5 row"><Link to="/blogs" className={source=="Blogs" ? "exoR item_menu active" : "exoR item_menu "} >Blogs</Link></li>
                <li className="marlr5 row"><Link to="/casos_de_exito" className={source=="Casos" ? "exoR item_menu active" : "exoR item_menu "} >Casos de Éxito</Link></li>
                <li className="marlr5 row"><Link to="/contacto" className={source=="Contacto" ? "exoR item_menu active" : "exoR item_menu "} >Contacto</Link></li>
                
            </ul>
            <div className="btn_close" onClick={close_open}>
        <button type="button" className="hamburger">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
      </div>
        </div>
    </header>)
}

export default Header;