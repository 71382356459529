function Banner({ name, text }) {
    return (
        <div className={`banner_60 row ${name}`}>
            <div className="mw800px">
                <h1 className="banner_title text_white">{text}</h1>

            </div>
        </div>
    )
}

export default Banner;