import Base from "./Base";
import onexo_ic_valores from "./../static/images/onexo_ic_valores.png";
import onexo_inicio_nosotros from "./../static/images/onexo_inicio_nosotros.png";
import "./../static/css/Nosotros.css";
import MetaTags from 'react-meta-tags';
import Banner from "./Snippets/Banner";
import Bounce from "react-reveal/Bounce";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import Slide from "react-reveal/Slide";
import TextBox from "./Snippets/TextBox";
import CardLine from "./Snippets/CardLine";
import onexo_ci_nosotros1 from "./../static/images/onexo_ci_nosotros1.webp";
import onexo_ci_nosotros2 from "./../static/images/onexo_ci_nosotros2.webp";

import React, { useState, useEffect } from "react";

const MisionVision = () => {
  return (
    <section id="mosion_vision">
      <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
      <div className="row wrap">
        <div className="row padtb50">
          <TextBox
            title={"Misión"}
            description={
              "Potenciar la expansión y crecimiento de los negocios de nuestros clientes a través de su transición o mejora en el mercado digital y móvil."
            }
            image={onexo_ci_nosotros1}
            orientation={"right"}
            color={"orange"}
            icon={faFlag}
          />
        </div>
        <div className="row padtb50">
          <TextBox
            title={"Visión"}
            description={
              "Posicionarnos cómo el socio más confiable de México en el sector de aplicaciones móviles con un impacto social y positivo que nos sitúe entre las 3 mejores empresas conocidas hacia el tercer trimestre del 2023."
            }
            image={onexo_ci_nosotros2}
            orientation={"left"}
            color={"black"}
            icon={faBullseye}
          />
        </div>
      </div>
    </section>
  );
};

function Valores() {
  return (
    <section className="digitalizamos pad50 row wrap_mw" id="valores">
      <div className="row wrap mw500px jcleft pad50">
        <Bounce>
          <h2 className="title">Valores</h2>
        </Bounce>
        <Slide left cascade>
          <div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">01&nbsp;</span> Excelencia
            </div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">02&nbsp;</span>Calidad
            </div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">03&nbsp;</span>Normatividad
            </div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">04&nbsp;</span>Integridad
            </div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">05&nbsp;</span>Pasión
            </div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">06&nbsp;</span>Innovación
            </div>
            <div className="row subtitle jcleft576 text_white padtb10">
              <span className="text_orange hid576">07&nbsp;</span>
              Responsabilidad
            </div>
          </div>
        </Slide>
      </div>
      <div className="row wrap mw600px">
        <img
          src={onexo_ic_valores}
          alt="onexo_bp_continuidad"
          className="row"
          title="continuidad"
        />
      </div>
    </section>
  );
}

function Historia() {
  return (
    <section id="historia" className="historia padtb50">
      <div className="row wrap">
        <div className="padlr50 row wrap mw1200px">
          <p className="second_text nunito row jcleft">Historia</p>
          <h2 className="title text_orange row jcleft">Nuestro viaje</h2>
        </div>
        <div className="row mw1200px">
          <div className="row wrap">
            <div className="row cards_line padtb30 jcleft2 wrap">
              <p className="nunito text_orange row jcleft hid">
                <FontAwesomeIcon icon={faCircle} />
                &nbsp; INICIO
              </p>
              <div className="row jcleft2">
                <span className="lines hid"></span>
                <CardLine
                  year={2019}
                  text={
                    "Con la misión de ayudar a los negocios a sacar provecho del marketing digital, ofrecimos una amplia gama de servicios como la creación de marcas, branding, social media y diseño web, trabajando con clientes de diferentes sectores como el restaurantero."
                  }
                  inicio={true}
                />
                <CardLine
                  year={2022}
                  text={
                    "Con la intención de servir mejor a nuestros clientes y aprovechar las tendencias actuales del mercado, concentramos nuestros esfuerzos en los servicios de desarrollo, diseño y soporte de páginas web, portales transaccionales, aplicaciones móviles y tecnologías emergentes."
                  }
                  inicio={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const Content = () => {
  return (
    <section className="content">
      <Banner
        name={"nosotros"}
        text={
          "Nuestro propósito es fusionar el mundo digital y el de los negocios."
        }
      />
      <MisionVision />
      <Valores />
      <Historia />
    </section>
  );
};

export default function Home() {
  return <Base Content={Content} source={"Nosotros"} />;
}
