import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function BlogCard({ img, title, description, autor, date, link, mini_img }) {
  return (
    <div className="blog_card">
      <div className="img_blog">
        <Link to={link}>
          <img src={mini_img} alt={title} className="img_blog" />
        </Link>
      </div>
      <div className="mini_blog_content row">
        <div className="row_change wrap padlr10">
          <div className="row_change sub3 pad10 tleft bold">{title}</div>
          <div className="row_change padlr10 justify des_blog">
            {description}
          </div>
          <div className="row_change vcenter marlr10">
            <div className="row">
              <div className="row wrap">
                <div className="row_change autor_text tleft">Por {autor}</div>
                <div className="row_change nunito text_orange">{date}</div>
              </div>
              <div className="row_change right">
                <Link to={link} className="btn margintb15">
                  Leer más
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
