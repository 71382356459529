import onexo_footer from "./../../static/images/onexo_footer.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Footer({ source }) {
  return (
    <>
      <footer className="row exoR text_white wrap" id="footer_desktop">
        <div className="padtb50 mw1000px">
          <div className="row padlr50">
            <div className="w50">
              <div className="row wrap ">
                <div className="row margin10 jcleft">
                  <img title="logo" src={onexo_footer} alt="onexo" height={60} />
                </div>
              </div>
            </div>
            <div className="w50">
              <div className="row jcleft">
                <h3 className="fsize32">El camino al éxito es digital</h3>
              </div>
            </div>
          </div>
          <div className="row padlr50">
            <div className="w50">
              <div className="row jcleft margin10">
                <span className="exoB">Tel:&nbsp;</span>
                <a href="tel:+525522321212" className="text_orange">
                  {" "}
                  +52 552 232 1212
                </a>
              </div>
              <div className="row jcleft margin10">
                Síguenos en redes sociales:
              </div>
              <div className="row jcleft margin10 fsize23">
                <a href="https://es-la.facebook.com/onexo.mex/" className="marlr10 text_orange">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/onexomx/" className="marlr10 text_orange">
                  <FontAwesomeIcon icon={faInstagramSquare} />
                </a>
                <a href="https://www.linkedin.com/company/onexo" className="marlr10 text_orange">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
            <div className="w50">
              <div className="row jcleft">
                <Link to="/" className={
                  source == "Home"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >INICIO</Link>

                <Link to="/casos_de_exito" className={
                  source == "Casos"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >CASOS DE ÉXITO</Link>
              </div>
              <div className="row jcleft">
                <Link to="/servicios" className={
                  source == "Servicios"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >SERVICIOS</Link>

                <Link to="/contacto" className={
                  source == "Contacto"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >CONTACTO</Link>

              </div>
              <div className="row jcleft">
              <Link to="/nosotros" className={
                  source == "Nosotros"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >NOSOTROS</Link>
                <Link to="/blogs" className={
                  source == "Blogs"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >BLOGS</Link>


              </div>
              <div className="row jcleft">
               

                <Link to="/aviso_de_privacidad" className={
                  source == "Aviso_de_privacidad"
                    ? "exoR text_orange item_footer"
                    : "exoR text_white item_footer"
                } >AVISO DE PRIVACIDAD</Link>

              </div>
            </div>
          </div>
        </div>

        <div className="row aviso">
          <p>Onexo S.A. de C.V. Todos los derechos reservados.</p>
        </div>
      </footer>
      <footer className="row exoR text_white wrap" id="footer_movil">
        <div className="pad50">
          <div className="row">
            <img title="logo" src={onexo_footer} alt="onexo" height={60} />
          </div>
          <div className="row margintb15">
            <span className="exoB">Tel:&nbsp;</span>
            <a href="tel:+525522321212" className="text_orange">
              {" "}
              +52 552 232 1212
            </a>
          </div>
          <div className="row margintb15">Síguenos en redes sociales:</div>
          <div className="row margintb15 fsize23">
            <a href="https://es-la.facebook.com/onexo.mex/" className="marlr10 text_orange">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/onexomx/" className="marlr10 text_orange">
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
            <a href="https://www.linkedin.com/company/onexo" className="marlr10 text_orange">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="row">
            <h3 className="fsize32 margintb15">El camino al éxito es digital</h3>
          </div>

          <div className="row">
            <Link to="/" className={
              source == "Home"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            } >INICIO</Link>

          </div>
          <div className="row">
            <Link to="/casos_de_exito" className={
              source == "Casos"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            } >CASOS DE ÉXITO</Link>

          </div>
          <div className="row">
            <Link to="/servicios" className={
              source == "Servicios"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            }>SERVICIOS</Link>


          </div>
          <div className="row">
            <Link to="/contacto" className={
              source == "Contacto"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            }>CONTACTO</Link>

          </div>
          <div className="row">
            <Link to="/nosotros" className={
              source == "Nosotros"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            }>NOSOTROS</Link>


          </div>
          <div className="row">
            <Link to="/blogs" className={
              source == "Blogs"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            }>BLOGS</Link>
            

          </div>
          <div className="row">
            <Link to="/aviso_de_privacidad" className={
              source == "Aviso_de_privacidad"
                ? "exoR text_orange item_footer"
                : "exoR text_white item_footer"
            }>AVISO DE PRIVACIDAD</Link>

          </div>
        </div>

        <div className="row aviso">
          <p>Onexo S.A. de C.V. Todos los derechos reservados.</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
