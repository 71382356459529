import React, { useEffect } from "react";
import {Rings} from  'react-loader-spinner'
import MetaTags from 'react-meta-tags';
function Mail() {

  useEffect(() => {
    window.location.href = "https://a2plcpnl0254.prod.iad2.secureserver.net:2096/";
  }, []);

  return (
    <div className="load">
      <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
        <Rings 
        color="#ff6633"
        />
    </div>
    
  );
}

export default Mail;