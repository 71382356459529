import Base from "./Base";
import Banner from "./Snippets/Banner";
import React, { useState, useEffect } from "react";
import "./../static/css/Home.css";
import MetaTags from 'react-meta-tags';
import onexo_servicios_web from "./../static/images/onexo_servicios_web.webp";
import onexo_servicios_apps from "./../static/images/onexo_servicios_apps.webp";
import onexo_servicios_ui from "./../static/images/onexo_servicios_ui.webp";
import "animate.css/animate.min.css";
import TextBox from "./Snippets/TextBox";
const Content = () => {
  function Servicios() {
    const [serviceActive, setServiceActive] = useState("web");
    function onClick(e) {
      var id = e.currentTarget.id;
      if (serviceActive != id) setServiceActive(id);
    }

    return (
      <section className="servicios padtb50" id="servicios">
        <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
        <div className="row wrap">
          <div className="row">
            <TextBox
              title={"Desarrollo web"}
              description={
                "Creamos sitios y aplicaciones web, siempre a la par de las tendencias tecnológicas. Nuestro proceso de desarrollo se basa en codificar pensando en el usuario, lo que, junto con el diseño y la optimización, garantizará la mejor experiencia para sus clientes y el máximo éxito para su empresa."
              }
              image={onexo_servicios_web}
              orientation={"right"}
              color={"orange"}
            />
          </div>
          <div className="row padtb50">
            <TextBox
              title={"Desarrollo móvil"}
              description={
                "En la era del dispositivo móvil, donde hay una necesidad constante de estar conectado e informado, estamos preparados para ayudarle a desarrollar aplicaciones que faciliten la comunicación y la movilidad con sus clientes y colaboradores. Nuestro equipo crea aplicaciones para cualquier dispositivo y comprende en su totalidad las necesidades que la aplicación puede abordar."
              }
              image={onexo_servicios_apps}
              orientation={"left"}
              color={"black"}
            />
          </div>
          <div className="row">
            <TextBox
              title={"Diseño de interfaces de usuario"}
              description={
                "Estudiamos a fondo el comportamiento del usuario para recopilar criterios que sienten las bases de una interfaz fluida, funcional e intuitiva. Alcance sus metas empresariales con una experiencia ideal para el usuario."
              }
              image={onexo_servicios_ui}
              orientation={"right"}
              color={"orange"}
            />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="content row wrap">
      <Banner name={"nosotros"} text={"Servicios"} />
      <Servicios />
    </section>
  );
};

export default function Home() {
  return <Base Content={Content} source={"Servicios"} />;
}
