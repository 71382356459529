
function CardLine({ year, text, inicio }) {
    return (
        <div className="card_line">
            
            <div className="row ">
                <div className={`subcard`}>
                    <div className="exoB fsize32 ">
                        {year}
                    </div>
                    <div className="row padtb30 nunito">
                        {text}
                    </div>
                </div>
            </div>

        </div>
    )
}
export default CardLine