
import "./../static/css/404.css";

import "animate.css/animate.min.css";

import Tilt from 'react-parallax-tilt';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
const Content = () => {
    return (
        <div className="tilt">

            <section className="content row wrap P404">
                <Tilt>
                    <div className="row"><br /></div>
                    <div className="row wrap">
                        <div className="row">
                            <h1 className="exoB text_white main_text">404</h1>
                        </div>
                        <div className="row">
                            <h3 className="nunito text_white second_text">
                                ¡Huye antes de que llegues al horizonte de eventos!
                            </h3>
                        </div>
                        <div className="row margintb15">
                        <Link to="/" className="btn_servicio active margintb15" >Inicio</Link>
                        </div>
                    </div>
                    
                </Tilt>
            </section>

        </div>


    );
};

export default function P404() {
    return <Content />;
}