import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Slide from 'react-reveal/Slide';
import Zoom from "react-reveal/Zoom";
function Menu({toggleMenu,close_open,toggleMenuClose, source}) {
    
  return (
    <div className={toggleMenuClose? toggleMenu ? 'menu row active' : 'menu row close':'menu row'}>
      <div className="close_menu" onClick={close_open}>
        <button type="button" className="hamburger active">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
      </div>
      <ul>
      <Zoom cascade >
      <li className="padlr20 row"><Link to="/" className={source=="Home" ? "exoB item_menu active" : "exoB item_menu "} >Inicio</Link></li>
      <li className="padlr20 row"><Link to="/servicios" className={source=="Servicios" ? "exoB item_menu active" : "exoB item_menu "} >Servicios</Link></li>
      <li className="padlr20 row"><Link to="/nosotros" className={source=="Nosotros" ? "exoB item_menu active" : "exoB item_menu "} >Nosostros</Link></li>
      <li className="marlr5 row"><Link to="/blogs" className={source=="Blogs" ? "exoB item_menu active" : "exoB item_menu "} >Blogs</Link></li>      
      <li className="padlr20 row"><Link to="/casos_de_exito" className={source=="Casos" ? "exoB item_menu active" : "exoB item_menu "} >Casos de Éxito</Link></li>
      <li className="padlr20 row"><Link to="/contacto" className={source=="Contacto" ? "exoB item_menu active" : "exoB item_menu "} >Contacto</Link></li>
            
      </Zoom>
      
      </ul>
    </div>
  );
}

export default Menu;
