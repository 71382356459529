import Base from "./Base";
import MetaTags from "react-meta-tags";
const Content = () => {
  return (
    <section className="content nunito row hcenter" style={{ padding: 20 }}>
      <MetaTags>

        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta
          name="description"
          content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad."
        />
        <meta
          name="og:image"
          content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
        />
        <meta property="og:type" content="article" />
      </MetaTags>
      <div className="mw1120px">
        <p style={{textAlign:"center", margin:20}} >
          <strong>AVISO DE PRIVACIDAD INTEGRAL</strong>
        </p>

        <p>
          ONEXO S.A.DE
          C.V., (en lo sucesivo &ldquo;ONEXO&rdquo;), con domicilio en
          Av. 15 de Mayo N&deg; 10 Fraccionamiento Club de Golf las Fuentes
          Puebla, Pue. en calidad de responsable de recabar sus datos
          personales, de conformidad con la Ley Federal de Protecci&oacute;n de
          Datos Personales en Posesi&oacute;n de los Particulares, su reglamento
          y dem&aacute;s disposiciones aplicables hace de su conocimiento el
          presente:
        </p>

        <p>Datos Personales</p>

        <p>Los datos personales que ONEXO maneja son:</p>

        <p>Datos de identificaci&oacute;n y contacto:</p>

        <p>1. Nombre completo</p>

        <p>2. Correo electr&oacute;nico</p>

        <p>3. Empresa</p>

        <p>4. No. De tel&eacute;fono</p>

        <p>5. Puesto en archivos de (descargas)</p>

        <p>
          Existen opciones dentro de nuestro sitio web donde podremos obtener
          datos personales de una tercera persona (por ejemplo, un sitio de
          Internet puede permitirle mandar informaci&oacute;n de nuestros
          servicios a un amigo, en cuyo caso requerimos el nombre de esa persona
          y su direcci&oacute;n de correo electr&oacute;nico). Para ello
          necesitamos que usted cuente con el consentimiento de dicha persona,
          por lo que, si nos env&iacute;a la citada informaci&oacute;n,
          entenderemos que ha solicitado previamente la autorizaci&oacute;n del
          titular de los datos, para compartirlo con nosotros.
        </p>

        <p>
          <strong style={{marginTop:20}}>Datos personales de menores de edad </strong>
        </p>

        <p>
          En t&eacute;rminos generales nuestro sitio web y los servicios que
          ofrecemos no se destinan a menores de edad. Sin embargo, en dado caso
          que se obtengan, usen o almacenen datos personales de menores de edad,
          se har&aacute; con el consentimiento de sus padres o tutores. Cuando
          un menor se identifica como tal, incorporamos instrucciones para
          obtener el consentimiento de sus padres o tutores antes de que el
          menor nos proporcione cualquier dato de car&aacute;cter personal.
        </p>

        <p>
          Si usted es padre/madre o tutor de alg&uacute;n menor de edad que haya
          proporcionado sus datos personales sin su consentimiento, puede
          solicitar el ejercicio de cualquiera de los derechos ARCO enviando un
          correo electr&oacute;nico a ________________
        </p>

        <p>
          <strong>Finalidades del tratamiento de los datos personales</strong>
        </p>

        <p>
          ONEXO tratar&aacute; sus datos para las siguientes
          finalidades:
        </p>

        <p>Primarias:</p>

        <p>▪ Establecer y verificar la identidad de los usuarios.</p>

        <p>
          ▪ Verificar la identidad de aquellas personas que visitan nuestro
          sitio web
        </p>

        <p>▪ Mantener la seguridad e integridad de nuestros sistemas.</p>

        <p>
          ▪ Abrir, mantener, administrar y dar seguimiento a las cuentas que
          tiene con nosotros.
        </p>

        <p>▪ Prestar servicios y asistencia.</p>

        <p>
          ▪ Responder a sus preguntas, requerimientos de informaci&oacute;n,
          comentarios o sugerencias.
        </p>

        <p>Secundarias:</p>

        <p>
          ▪ Para enviarle actualizaciones sobre los servicios que son de su
          inter&eacute;s, avisos de promociones, ofertas y otra
          informaci&oacute;n de ONEXO.
        </p>

        <p>
          ▪ Se velar&aacute; por la protecci&oacute;n de correos
          electr&oacute;nicos proporcionados por los usuarios.
        </p>

        <p>
          ▪ Para entender sus necesidades y el modo en el que podemos mejorar
          nuestros servicios para mejorar nuestro sitio web tomando en cuenta
          sus preferencias.
        </p>

        <p>
          En ocasiones ONEXO obtiene ciertos datos de fuentes de acceso
          p&uacute;blico, a fin de contactarlo y proporcionarle
          informaci&oacute;n sobre nuestros servicios.
        </p>

        <p>
          En dichos casos usted podr&aacute; en todo momento optar por dejar de
          recibir nuestra informaci&oacute;n envi&aacute;ndonos un correo a:
          __________, solicitando que dejemos de tratar sus datos personales.
        </p>

        <p>
          <strong>Transferencias de datos personales </strong>
        </p>

        <p>
          &Uacute;nicamente en los casos descritos en la Ley, tales como:
          cumplimiento a disposiciones legales, se llevar&aacute; a cabo la
          transferencia de sus datos personales.
        </p>

        <p>
          <strong>Derechos del titular respecto a sus datos personales </strong>
        </p>

        <p>
          Usted tiene derecho de acceder a sus datos personales y a conocer los
          detalles del tratamiento de los mismos, a rectificarlos en caso de ser
          inexactos o incompletos; cancelarlos cuando considere que ya no son
          necesarios para cumplir con las finalidades que justificaron su
          obtenci&oacute;n, as&iacute; como a oponerse a su tratamiento por una
          causa leg&iacute;tima (Derechos &ldquo;ARCO&rdquo;). De igual forma,
          usted puede limitar el uso y divulgaci&oacute;n de sus datos, o bien,
          revocar el consentimiento que haya otorgado para su tratamiento.
        </p>

        <p>
          El mecanismo implementado para el ejercicio de derechos es a
          trav&eacute;s de la presentaci&oacute;n de la solicitud respectiva al
          correo electr&oacute;nico __________
        </p>

        <p>Su solicitud, deber&aacute; contener:</p>

        <p>
          ▪ El nombre del titular y domicilio u otro medio para comunicarle la
          respuesta a su solicitud;
        </p>

        <p>
          ▪ Los documentos que acrediten la identidad o, en su caso, la
          representaci&oacute;n legal del titular;
        </p>

        <p>
          ▪ La descripci&oacute;n clara y precisa de los datos personales
          respecto de los que se busca ejercer alguno de los derechos antes
          mencionados; y cualquier otro elemento o documento que facilite la
          localizaci&oacute;n de los datos personales.
        </p>

        <p>
          ▪ En el caso de solicitudes de rectificaci&oacute;n, el titular
          deber&aacute; indicar, adem&aacute;s de lo anterior, las
          modificaciones a realizarse y aportar la documentaci&oacute;n que
          sustente su petici&oacute;n.
        </p>

        <p>
          ONEXO le comunicar&aacute;, en un plazo m&aacute;ximo de _____
          d&iacute;as h&aacute;biles, contados desde la fecha en que se
          recibi&oacute; completa la solicitud de acceso, rectificaci&oacute;n,
          cancelaci&oacute;n u oposici&oacute;n, la determinaci&oacute;n
          adoptada, a efecto de que, si resulta procedente, se haga efectiva
          dentro de los ______ d&iacute;as h&aacute;biles siguientes a la fecha
          en que se comunica la respuesta. Los plazos antes referidos
          podr&aacute;n ser ampliados una sola vez por un periodo igual, siempre
          y cuando as&iacute; lo justifiquen las circunstancias del caso.
        </p>

        <p>
          Usted puede dejar de recibir mensajes promocionales en el correo
          electr&oacute;nico, envi&aacute;ndonos un correo electr&oacute;nico a
          ________ O bien, si as&iacute; lo desea puede inscribirse al Registro
          P&uacute;blico para Evitar Publicidad, de la Procuradur&iacute;a
          Federal del Consumidor en la siguiente liga:
          https://repep.profeco.gob.mx/
        </p>

        <p>
          <strong>Cookies o web Beacons</strong>
        </p>

        <p>
          Nuestro sitio web recaba autom&aacute;ticamente determinada
          informaci&oacute;n no personal sobre el uso de nuestro sitio, la cual
          se almacena en nuestros servidores para fines exclusivamente internos,
          como pueden ser facilitar su visita a nuestro sitio, mejorar su
          experiencia online o para finalidades estad&iacute;sticas de acceso.
        </p>

        <p>
          Para los fines antes mencionados, tambi&eacute;n podemos utilizar
          &ldquo;cookies&rdquo;, web beacons y otras tecnolog&iacute;as. Las
          &ldquo;cookies&rdquo; son archivos de texto que son descargados
          autom&aacute;ticamente y almacenados en el disco duro del equipo de
          c&oacute;mputo del usuario al navegar en una p&aacute;gina de Internet
          espec&iacute;fica; permiten recordar al servidor de Internet algunos
          datos sobre este usuario, entre ellos, sus preferencias para la
          visualizaci&oacute;n de las p&aacute;ginas en ese servidor, nombre y
          contrase&ntilde;a. Los web beacons son im&aacute;genes insertadas en
          una p&aacute;gina de Internet o correo electr&oacute;nico, que pueden
          ser utilizadas para monitorear el comportamiento de un visitante, para
          almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario,
          duraci&oacute;n del tiempo de interacci&oacute;n en dicha
          p&aacute;gina y el tipo de navegador utilizado, entre otros.
        </p>

        <p>
          Las &ldquo;cookies&rdquo; y web beacons nos permitir&aacute;n adecuar
          una p&aacute;gina web o anuncio a sus intereses y preferencias. En la
          mayor&iacute;a de los navegadores de Internet usted puede eliminar las
          &ldquo;cookies&rdquo; del disco duro de su ordenador, bloquearlas o
          recibir un aviso antes de que se instale una &ldquo;cookie&rdquo;. Por
          favor, consulte las instrucciones de su navegador o la pantalla de
          ayuda para saber m&aacute;s sobre el funcionamiento de estas
          tecnolog&iacute;as y c&oacute;mo deshabilitarlas.
        </p>

        <p>
          <strong>Medidas de Seguridad </strong>
        </p>

        <p>
          ONEXO ha establecido y mantiene las medidas de seguridad,
          administrativas, t&eacute;cnicas y f&iacute;sicas que le permiten
          proteger sus datos personales contra da&ntilde;o, p&eacute;rdida,
          alteraci&oacute;n, destrucci&oacute;n o contra el uso, acceso o
          tratamiento no autorizado, en t&eacute;rminos de la legislaci&oacute;n
          aplicable.
        </p>

        <p>
          <strong>Cambios al aviso de privacidad </strong>
        </p>

        <p>
          En caso de modificaciones al presente Aviso de Privacidad. Estas
          modificaciones estar&aacute;n disponibles al p&uacute;blico a
          trav&eacute;s de los siguientes medios: (i) anuncios visibles en
          nuestros establecimientos; (ii) tr&iacute;pticos o folletos
          disponibles en nuestros establecimientos; (iii) en nuestra
          p&aacute;gina de Internet [secci&oacute;n aviso de privacidad]; (iv) o
          se las haremos llegar al &uacute;ltimo correo electr&oacute;nico que
          nos haya proporcionado.
        </p>

        <p>Uso de Redes Sociales &ldquo;Plug-ins&rdquo;</p>

        <p>
          Plug-ins (&quot;plug-ins&quot;) de las redes sociales facebook.com y
          Twitter pueden ser a&ntilde;adidos en nuestras p&aacute;ginas web. Los
          servicios asociados son proporcionados por las compa&ntilde;&iacute;as
          de Facebook Inc. y Twitter Inc., respectivamente
          (&quot;Proveedores&quot;).
        </p>

        <p>
          Facebook es operado por Facebook Inc., 1601 S. California Ave, Palo
          Alto, CA 94304, EE.UU. (&quot;Facebook&quot;). Para una visi&oacute;n
          general del Facebook plug-ins y su apariencia, vaya a:
          https://developers.facebook.com/docs/plugins
        </p>

        <p>
          Twitter es operado por Twitter Inc., 1355 Market St, Suite 900, San
          Francisco, CA 94103, EE.UU.. Para una visi&oacute;n general de los
          opciones de Twitter y su aspecto, vaya a:
          https://dev.twitter.com/web/tweet-button
        </p>

        <p>
          Para aumentar la protecci&oacute;n de los datos cuando visita nuestras
          p&aacute;ginas web, estos plug-ins se implementan como &quot;2-click
          buttons&quot;. Esta forma de integraci&oacute;n asegura que, al
          acceder a una p&aacute;gina dentro de nuestra p&aacute;gina web que
          contenga dichos plug-ins, no est&aacute;n conectado
          autom&aacute;ticamente a los servidores de los proveedores.
          S&oacute;lo si activa los plug-ins, emitiendo as&iacute; un permiso
          para la transmisi&oacute;n de datos, el navegador crear&aacute; un
          v&iacute;nculo directo a los servidores de los proveedores. El
          contenido de los diversos plug-ins se transmite por el proveedor en
          cuesti&oacute;n directamente en el navegador y luego aparece en la
          pantalla.
        </p>

        <p>
          El plug-in le dice al proveedor cu&aacute;les de nuestras
          p&aacute;ginas web ha accedido. Si, mientras est&aacute; viendo
          nuestro sitio web, se inicia la sesi&oacute;n en su cuenta de usuario
          de Facebook o Twitter, el proveedor es capaz de compaginar su
          inter&eacute;s, es decir, la informaci&oacute;n que usted est&aacute;
          accediendo con su cuenta de usuario. Al hacer uso de cualquiera de las
          funciones de plug-in (por ejemplo, al hacer clic en el bot&oacute;n
          &quot;Me gusta&quot;, dejando un comentario), esta informaci&oacute;n
          tambi&eacute;n ser&aacute; transmitida por el navegador directamente
          al proveedor para registrarlo.
        </p>

        <p>
          Para m&aacute;s informaci&oacute;n sobre esto y uso de datos por
          Facebook o Twitter, as&iacute; como sobre los derechos y posibilidades
          disponibles para proteger su privacidad en estas circunstancias, se
          puede encontrar en los avisos de protecci&oacute;n de datos /
          privacidad de los proveedores:
        </p>

        <p>
          Protecci&oacute;n de datos / aviso de privacidad emitido por Facebook:
          http://www.facebook.com/policy.php Protecci&oacute;n de datos / aviso
          de privacidad emitido por Twitter: https://twitter.com/privacy Para
          evitar el permitir que Facebook o Twitter vinculen su visita de
          nuestro sitio web a su cuenta de usuario con estos proveedores,
          tendr&aacute; que desconectarse de la cuenta de usuario
          correspondiente antes de acceder a nuestras p&aacute;ginas web.
        </p>

        <p>
          <strong>Descarga de contenido en E-BOOKS</strong>
        </p>

        <p>
          El contenido de este sitio es propiedad de ONEXO y se
          encuentra protegido por las leyes de la propiedad intelectual. Las
          marcas registradas, comerciales y de servicios, como as&iacute;
          tambi&eacute;n los logotipos y blogs mencionados en este sitio
          est&aacute;n legalmente protegidos y no se podr&aacute;n utilizar
          ninguno de ellos sin el consentimiento previo y por escrito de ONEXO. No obstante, es usted bienvenido a descargar su contenido,
          solo para uso personal, no comercial. No se autoriza ninguna
          modificaci&oacute;n ni reproducci&oacute;n del contenido y &eacute;ste
          no podr&aacute; ser copiado ni utilizado de ninguna otra manera.
        </p>
      </div>
    </section>
  );
};

export default function Home() {
  return <Base Content={Content} source={"Aviso"} />;
}
