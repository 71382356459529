import * as THREE from "three"
import { extend } from "react-three-fiber"

class DotMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: { time: { value: 1 } },
      vertexShader: `uniform float time;
      attribute float size;

      float cubicPulse( float c, float w, float x ){
        x = abs(x - c);
        if( x>w ) return 0.0;
        x /= w;
        return 1.0 - x*x*(3.0-2.0*x);
      }

      void main() {
        float PI = 3.1415926538;
        float ROW = 100.;
        float COL = 100.;
        float NUM = ROW * COL;
        float x = position.x;
        float y = position.y;
        float id = position.z;

        float distance = length(vec2(x/COL,y/ROW) - vec2(0.5));
        float maxDist = 0.01;
        float normDistance = (distance/maxDist) * 0.01;
        float amount = 0.1;
        float pulse = cubicPulse(mix(0.1, 1. - amount, normDistance), amount, pow(mod((time / 8.), 1.), 1. + pow(normDistance, 2.)));    
        float pulse2 = cubicPulse(mix(0.1, 1. - amount, normDistance), amount, pow(mod((time / 6.), 1.), 1. + pow(normDistance, 2.)));    
        vec3 pos = vec3(
          x ,
          y,
          -20.
          + 10.0* (pulse / mix(4., 1., (normDistance * 1.0)))+
          + 4.0* (pulse2 / mix(4., 1., (normDistance * 1.0)))+
            0.2 * (cos((4. * PI * (x - COL / 2.)) / COL + time) + sin((8. * PI * (y - ROW / 2.)) / ROW + time)) +
            0.2 * (cos((12. * PI * (x - COL / 2.)) / COL + time) + sin((17. * PI * (y - ROW / 2.)) / ROW + time))
        );
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0 );
        gl_PointSize = size;
      }`,
      fragmentShader: `uniform float time;
      void main() {
        gl_FragColor = vec4(vec3(1.,1.,1.), step(length(gl_PointCoord.xy - vec2(0.5)), 0.5));
      }`
    })
  }
}

extend({ DotMaterial })
