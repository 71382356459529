import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
import { useSpring, animated } from "react-spring";
import { Swiper, SwiperSlide } from "swiper/react";
import { Icon } from "@iconify/react";
import "swiper/css";

import "swiper/css/effect-cards";
import { EffectCards } from "swiper";

import Zoom from "react-reveal/Zoom";
import Base from "./Base";
import React, {
  useMemo,
  useRef,
  Suspense,
  useState,
  Component,
  useEffect,
} from "react";
import "./dotmaterial";
import "animate.css/animate.min.css";

const AnimatedOrbitControls = animated(OrbitControls);

const News = () => {
  function Orbit() {
    const initialCoords = [];
    const initialSizes = [];
    const pointCount = 4000;
    const radio = 60;

    const [coords, sizes] = useMemo(() => {
      for (let point = 0; point < pointCount; point += 1) {
        let ratio = 250;
        let angx = Math.random() * (180 - -180) + -180;
        let angy = Math.random() * (180 - -180) + -180;
        let lat = (angx / 180) * Math.PI;
        let long = (angy / 180) * Math.PI;
        let z = Math.cos(lat) * Math.cos(long) * ratio;
        let x = Math.cos(lat) * Math.sin(long) * ratio;
        let y = Math.sin(lat) * ratio;
        initialCoords.push(x);
        initialCoords.push(y);
        initialCoords.push(z);
        initialSizes.push(5 + Math.cbrt(x * y * z) / 10);
      }
      const coords = new Float32Array(initialCoords);
      const sizes = new Float32Array(initialSizes);
      return [coords, sizes];
    }, []);
    const ref = useRef();
    useFrame((state, delta) => [
      [(ref.current.rotation.y += 0.001), (ref.current.rotation.x += 0)],
    ]);
    return (
      <points position={[0, 0, 0]} ref={ref}>
        <bufferGeometry>
          <bufferAttribute
            attachObject={["attributes", "position"]}
            count={coords.length / 3}
            array={coords}
            itemSize={3}
          />
          <bufferAttribute
            attachObject={["attributes", "size"]}
            count={sizes.length}
            array={sizes}
            itemSize={1}
          />
        </bufferGeometry>
      </points>
    );
  }

  function Banner() {
    return (
      <div className="row header_universo">
        <div className="mw800px row_change center nunito text_white texto_header wrap">
          <Zoom casacade>
            <div className="row"><h1 className="t_center">La mejor forma de predecir el futuro es crearlo.</h1></div>
            <div className="row"><h3>-Peter Drucker-</h3></div>
            
          </Zoom>
        </div>

        <div className="canvas">
          <Canvas
            pixelRatio={[1, 2]}
            camera={{ position: [0, 0, -500], rotation: [0, 0, 0] }}
          >
            <Orbit />
            <AnimatedOrbitControls
              enablePan={false}
              maxDistance={300}
              minDistance={200}
              scrollLeft={true}
            />
          </Canvas>
        </div>
      </div>
    );
  }

  return (
    <>
      <Banner />
    </>
  );
};


export default News