import Base from "./Base";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./dotmaterial";
import "animate.css/animate.min.css";
import MetaTags from 'react-meta-tags';
import BlogView from "./Snippets/BlogView";
import News from "./News";
import Blog from "./Snippets/Blog";
const baseURL = "https://onexo.mx:8443";

const axios_instance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
});
const app = "blog";
const Content = ({ request }) => {
  const params = useParams();
  if (params.slug)
    return <BlogView params={params} request={request} baseURL={baseURL} />;
  return <Blog request={request} baseURL={baseURL} app={app} />;
};

export default function Blogs() {
  function request(
    method,
    url_mothod,
    ok_action,
    error_action,
    message,
    params
  ) {
    
    if (method == "POST") {
      axios_instance
        .post(url_mothod, params)
        .then(function (response) {
          ok_action(response);
          return response;
        })
        .catch(function (error) {
          error_action(error);
          return error;
        });
    } else {
      axios_instance
        .get(url_mothod)
        .then(function (response) {
          ok_action(response);
          return response;
        })
        .catch(function (error) {
          error_action(error);
          return error;
        });
    }
  }
  return <Base Content={Content} source={"Blogs"} request={request} />;
}
