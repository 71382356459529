import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./Components/Home";
import About from "./Components/About";
import Nosotros from "./Components/Nosotros";
import Casos from "./Components/Casos";
import Contacto from "./Components/Contacto";
import Aviso from "./Components/Aviso";
import P404 from "./Components/P_404";
import Mail from "./Components/Mail";
import PdfViewer from "./Components/PdfViewer";
import Blogs from "./Components/Blogs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import News from "./Components/News";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

var state = {
  loading: true,
};

const baseURL = "https://onexo.mx:8443";
const axios_instance = axios.create({
  withCredentials: true,
  baseURL: baseURL, 
});

function request(url_mothod, ok_action, error_action, message) {
  const id = toast.loading(message, { toastId: message });
  axios_instance
    .get(url_mothod)
    .then(function (response) {
      toast.update(id, {
        render: response.data.message,
        type: "success",
        isLoading: false,
        autoClose: true,
        toastId: response.data.message,
      });
      ok_action(response, id);
      return response;
    })
    .catch(function (error) {
      toast.update(id, {
        render: `${error.response.status} ${error.response.data.detail.message}`,
        type: "error",
        isLoading: false,
        autoClose: true,
        toastId: `${error.response.status} ${error.response.data.detail.message}`,
      });
      error_action(error);
      return error;
    });
}

const fakeRequest = () => {
  return new Promise((resolve) => setTimeout(() => resolve(), 10));
};

const rootElement = ReactDOM.createRoot(document.getElementById("root"));

if (state.loading) {
  rootElement.render(null);
  fakeRequest().then(() => {
    const el = document.querySelector(".loader_container");
    if (el) {
      el.remove(); // removing the spinner element
      state.loading = false;
    }
  });
}

rootElement.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/404" element={<P404 />} />
      <Route path="/servicios" element={<About />} />
      <Route path="/nosotros" element={<Nosotros />} />
      <Route path="/casos_de_exito" element={<Casos />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/aviso_de_privacidad" element={<Aviso />} />
      <Route path="/mail" element={<Mail />} />
      <Route path="/uploads/contents/:pdf" element={<PdfViewer baseURL={baseURL} />} />
      
      <Route
        path="/blogs"
        element={
          <>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Blogs request={request} />
          </>
        }
      />
      <Route
        path="/blogs/:slug"
        element={
          <>
            {" "}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Blogs request={request} />
          </>
        }
      />
      <Route path="*" element={<Navigate replace to="/404" status={404} />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
