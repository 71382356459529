import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "animate.css/animate.min.css";
import Checklist from "./ChekList";
import MetaTags from 'react-meta-tags';
import Navbar_blog from "./Navbar_blog";
import Last_blog from "./Last_blog";
import BlogCard from "./CardBlog";
import { useEffect, useState, useRef, useCallback } from "react";
import { ThreeCircles } from "react-loader-spinner";
import News from "../News";
function Blog({ request, baseURL, app }) {
  const [ultimoB, setUltimoB] = useState(null);
  const [categorias, setCategorias] = useState(null);
  const [catActive, setCatActive] = useState(null);
  const [temas, setTemas] = useState(null);
  const [searched, setSearched] = useState(false);
  const [post, setPost] = useState([]);
  const [searching, setSearching] = useState(false);
  const [temaA, setTemaA] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function buscar() {
    setSearching(true);
    let formData = new FormData();
    formData.append("category", catActive);
    formData.append("tema", temaA);
    request(
      "POST",
      "blog/filter",
      (response) => {
        setSearching(false);
        if (response.status == 200) {
          setPost(response.data);
        }
        setSearched(true);
      },
      (error) => {
        setSearching(false);
        setSearched(true);
      },
      "Obteniendo datos",
      formData
    );
  }
  useEffect(() => {
  }, [searching]);

  useEffect(() => {
  }, [ultimoB]);
  useEffect(() => {
  }, [searched]);
  useEffect(() => {
    if (categorias != null) setCatActive(Object.keys(categorias)[0]);
  }, [categorias]);
  useEffect(() => {
    if (catActive != null && categorias != null)
      setTemas(categorias[catActive]);
    if (temaA) buscar();
  }, [catActive]);
  useEffect(() => {
    if (temas != null) {
      document.getElementById("tema").value = temas[0];
      setTemaA(temas[0]);
    }
  }, [temas]);
  useEffect(() => {
  }, [post]);
  useEffect(() => {
    if (temaA != null) {
      buscar();
    }
  }, [temaA]);
  if (ultimoB == null)
    request(
      "GET",
      app,
      (response) => {
        if (response.status == 200) {
          setUltimoB(response.data.data.ultimo);
          setCategorias(response.data.data.categorias);
          setCatActive(response.data.data.categorias[0]);
        }
        setSearched(true);
      },
      (error) => {
        setSearched(true);
      },
      "Obteniendo datos",
      null
    );
  if (!searched)
    return (
      <div className="row">
        <ThreeCircles
          height="70"
          width="70"
          color="#ff6633"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </div>
    );
  return (
    <>
    <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
    <News />
      <div className="row nunito">
        <div className="row mw1120px wrap">
          <Last_blog
            title={ultimoB.title}
            descripcion={ultimoB.descripcion}
            date={ultimoB.created}
            autor={ultimoB.autor}
            autor_img={ultimoB.autor_img}
            slug={ultimoB.slug}
            baseURL={baseURL}
            mini_img={ultimoB.mini_img}
            width={windowDimensions.width}
          />
          <div className="divisor margintb30"></div>
          <div className={`row margin10 ${windowDimensions.width <= 768 ? "wrap" : ""}`}>
            {windowDimensions.width > 768 ? (
              ""
            ) : (
              <div className="row_change wrap">
                <div className="row">
                  <Icon
                    icon="octicon:checklist-24"
                    className="text_orange f70px"
                  />
                  <div>
                    <h1 className="text_orange exoB f45px">Checklist</h1>
                    <div className="italic text_orange tleft">
                      Mejora tus sistemas informaticos
                    </div>
                  </div>
                </div>

                <div className="row">
                  <Fade bottom> <div className="row mw400px justify pad10">
                    <p>
                      En Onexo nos preocupamos por{" "} <span className="bold italic">potenciar</span> tu negocio en el
                      mercado digital y móvil, por lo que te brindamos procesos,
                      estrategias y <span className="bold italic">soluciones</span>  tecnológicas eficaces y efectivas
                      al poder de un <span className="bold italic">click</span>.
                    </p>
                  </div></Fade>
                 
                </div>
              </div>
            )}
            <Fade left>
            <div className="row margintb15">
              <Checklist request={request} baseURL={baseURL}/>
            </div>
            </Fade>
           
            {windowDimensions.width > 768 ? (
              <div className="row_change wrap">
                <div className="row">
                  <Icon
                    icon="octicon:checklist-24"
                    className="text_orange f70px"
                  />
                  <div>
                    <h1 className="text_orange exoB f45px">Checklist</h1>
                    <div className="italic text_orange tleft">
                      Mejora tus sistemas informaticos
                    </div>
                  </div>
                </div>

                <div className="row">
                  <Fade right><div className="row mw550px justify pad10 margintb15">
                    <p>
                    En Onexo nos preocupamos por{" "} <span className="bold italic">potenciar</span> tu negocio en el
                      mercado digital y móvil, por lo que te brindamos procesos,
                      estrategias y <span className="bold italic">soluciones</span>  tecnológicas eficaces y efectivas
                      al poder de un <span className="bold italic">click</span>.
                    </p>
                  </div></Fade>
                  
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row wrap margintb15">
            <form id="form_filter hidden" className="row wrap">
              <Navbar_blog
                categorias={categorias}
                catActive={catActive}
                setCatActive={setCatActive}
                width={windowDimensions.width}
              />
              <div className="row wrap margintb15 pad10">
                <div className="row padlr20">
                  <div className="second_text nunito row_change">
                    Blogs recientes
                  </div>
                  <div className="row_change subtitle text_orange margintb15 right">
                    <select name="tema" id="tema" className="select">
                      {temas
                        ? temas.map((tema) => (
                            <option
                              value={tema}
                              onClick={() => {
                                setTemaA(tema);
                              }}
                            >
                              {tema}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row blogs_content wrap margintb15">
                {searching ? (
                  <ThreeCircles
                    height="70"
                    width="70"
                    color="#ff6633"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                  />
                ) : post.length == 0 ? (
                  "No hay post para la busqueda"
                ) : (
                  post.map((blog) => (
                    <Fade bottom>
                      <BlogCard
                        img={`${baseURL}${blog.img}`}
                        mini_img={`${baseURL}${blog.mini_img}`}
                        title={blog.title}
                        description={blog.descripcion}
                        autor={blog.autor}
                        date={blog.created}
                        link={blog.slug}
                      />
                    </Fade>
                  ))
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
