function Btn_blog({ btn, active, setCatActive }) {
  function activebtn() {
    setCatActive(btn);
  }
  return (
    <div
      className={`btn_blog marlr10 ${active ? "active" : ""}`}
      onClick={activebtn}
    >
      {btn}
    </div>
  );
}

function Navbar_blog({ categorias, catActive, setCatActive,width }) {
  return (
    <div className={`row_change nav_blog ${(width>768)?"right":"center"}`}>
        <input type="hidden" value={catActive} name={"category"} id={"category"} />
      {Object.keys(categorias).map((categoria) => (
        <Btn_blog
          btn={categoria}
          active={categoria == catActive ? true : false}
          setCatActive={setCatActive}
        />
      ))}
    </div>
  );
}

export default Navbar_blog;
