import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TextBox({ title, description, image, orientation, color, icon }) {
    const Icon = () => {
        return (
            <><FontAwesomeIcon icon={icon} /> {" "} {title} </>
        )
    }
    const Image = () => {
        return (
            <div className={`row jc${orientation}${orientation=="left"?"2":""}`}>
                <div className={`row img_service ${orientation} ${color}`}>
                <img
                    src={image}
                    alt={image}
                    height="100%"
                    width="100%"
                    title='image'
                />
            </div>
            </div>
        )
    }

    return (
        <div className={"row service_info visible wrap_mw"} >
            {orientation == "left" ? <Image /> : ""}
            <div className="row wrap pad30">
                <div className="mw400px">
                    <div className="row jcleft">
                        <Bounce>
                            <h2 className="title"><Icon /></h2>
                        </Bounce>
                    </div>
                    <div className="row tetx_normal margintb15">
                        {orientation == "left" ? <Slide right><p>{description}</p></Slide> : <Slide left><p>{description}</p></Slide> }
                    </div>
                </div>

            </div>
            {orientation == "right" ? <Image /> : ""}
        </div>
    )
}

export default TextBox;