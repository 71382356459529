import Base from "./Base";
import Banner from "./Snippets/Banner"
import MetaTags from 'react-meta-tags';
import React, { useRef } from 'react';
import "./../static/css/Contacto.css";
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faPadlet } from "@fortawesome/free-brands-svg-icons";
import emailjs from '@emailjs/browser';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useForm } from "react-hook-form";

const Content = () => {
  function validateEmail(email) {
    return (email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
  }
  function phonenumber(inputtxt) {
    var phoneno = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    }
    else {
      return false;
    }
  }
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    if (validateEmail(data.email) && phonenumber(data.phone))
      sendEmail(data)
    else
      createNotification('error')
  }
  const createNotification = (type) => {
    switch (type) {
      case 'info':
        return (NotificationManager.info('Info message'))
      case 'success':
        return (NotificationManager.success('', 'Correo enviado correctamente'))
      case 'warning':
        NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
        break;
      case 'error':
        return (NotificationManager.error('', 'Algo salio mal, revisa los datos', 5000, () => {
          alert('callback');
        }))

    }

  }
  const form = useRef();

  const sendEmail = (data) => {
    try {
      emailjs.send('service_p5yje7t', 'template_deo9wbb', data, 'cVlhNpBZm42yKGwIK')
        .then((result) => {
          createNotification('success')
        }, (error) => {
          createNotification('error')
        });
    } catch (ex) {

    }


  };

  function Content() {
    return (
      <div className="row pad30 form">
        <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
        <div className="row wrap">
          <div className="mw500px">
            <div className="row jcleft pad10">
              <Bounce>
                <div className="title">
                  Hora de avanzar
                </div>
              </Bounce>

            </div>
            <Slide cascade left>
              <div className="row wrap ">
                <div className="row jcleft">
                  <div className="mw400px tetx_normal pad10">
                    Si necesitas saber más a cerca de nuestra oferta de servicios o poner en movimiento tu negocio sobre la carretera digital, déjanos tus datos y nos pondremos en contacto contigo.
                  </div>
                </div>
                <div className="row pad10 jcleft">
                  <span className="icon_contactor row text_white">
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <span className="nunitoB">Teléfono: </span>
                  <span className="nunito text_orange">&nbsp;+52 552 232 1212</span>
                </div>
                <div className="row pad10 jcleft">
                  <span className="icon_contactor row text_white">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <span className="nunitoB">Email: </span>
                  <span className="nunito text_orange">&nbsp;contacto@onexo.com</span>
                </div>
                <div className="row  jcleft pad10">
                  <span className="nunitoB">Dirección: </span>
                </div>
                <div className="row jcleft padlr10 nunito">
                  15 de Mayo 10, Club de Golf Las
                </div>
                <div className="row jcleft padlr10 nunito">
                  Fuentes, 72110. Puebla, Pue.
                </div>
              </div>
            </Slide>
          </div>
        </div>
        <div className="row jcleft margintb15">
          <Slide right cascade>
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row wrap576">
                <input type="text" className="input" placeholder="Nombre* " id="name" {...register("name", { required: true })} />
                <input type="text" className="input" placeholder="Nombre de la empresa*" id="empresa" {...register("empresa", { required: true })} />
              </div>
              <div className="row wrap_mw">
                <input type="email" className="input" id="email" placeholder="Correo electrónico*" {...register("email", { required: true })} />
                <input type="tel" className="input" placeholder="Teléfono*" id="phone" {...register("phone", {
                  required: true, minLength: {
                    value: 10,
                    message: "Debe de tener 10 caracteres"
                  }
                })} />
              </div>
              <div className="row">
                <textarea name="" {...register("message", { required: false })} cols="30" rows="10" id="message" className="input" placeholder="Mensaje" ></textarea>
              </div>
              <div className="row jcleft padlr10">
                <button type="submit" className="btn margintb15" >Enviar</button>
              </div>

            </form>
          </Slide>
        </div>
      </div>
    )
  }

  return (
    <section className="content row wrap">
      <Banner name={"contacto"} text={"Contacto"} />
      <Content />
      <NotificationContainer />
    </section>
  );
};

export default function Home() {
  return <Base Content={Content} source={"Contacto"} />;
} 