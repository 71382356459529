import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
function TextBoxContent({ title, Content, image, orientation, color }) {
    const Image = () => {
        return (
            <div className={`row jc${orientation}${orientation=="left"?"2":""}`}>
                <div className={`row img_service ${orientation} ${color}`}>
                <img
                    src={image}
                    alt={image}
                    height="100%"
                   title='image'
                />
            </div>
            </div>
        )
    }

    return (
        <div className={"row service_info visible wrap_mw"} >
            {orientation == "left" ? <Image /> : ""}
            <div className="row wrap pad30">
                <div className="mw400px">
                    <div className="row jcleft">
                        <Bounce>
                            <h2 className="title">{title}</h2>
                        </Bounce>
                    </div>
                    {orientation == "left" ? <Slide right><Content /></Slide> : <Slide left><Content /></Slide> }
                </div>

            </div>
            {orientation == "right" ? <Image /> : ""}
        </div>
    )
}

export default TextBoxContent