import { useParams } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import MetaTags from 'react-meta-tags';
function PdfViewer({ baseURL }) {
  
  const params = useParams();

  window.location=`${baseURL}/pdf/get_pdf/${params.pdf}`

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
    </div>
  );
}

export default PdfViewer;
