import Header from './Base/Header';
import Menu from './Base/Menu';
import Footer from './Base/Footer';
import '../static/css/style.css'
import React, { useState, useEffect } from "react";


function Base({ Content, source, request }) {
    useEffect(() => {
        document.getElementById('body').classList.remove('block')
        window.scrollTo(0, 0)
    }, [])
    const [toggleMenu, setToggleMenu] = useState(false)
    const [toggleMenuClose, setToggleMenuClose] = useState(false)
    
    const close_open = () => {
        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        };
        setToggleMenu(!toggleMenu)
        if (toggleMenuClose) {
            sleep(500).then(r => {
                setToggleMenuClose(!toggleMenuClose)
                document.getElementById('body').classList.remove('block')
            });
        }
        else {
            setToggleMenuClose(!toggleMenuClose)
            document.getElementById('body').classList.add('block')
        }
    }
    
    return (
        <>
            <Header close_open={close_open}
                setToggleMenu={setToggleMenu}
                toggleMenu={toggleMenu}
                source={source} />
            <Menu close_open={close_open}
                setToggleMenu={setToggleMenu}
                toggleMenu={toggleMenu}
                toggleMenuClose={toggleMenuClose}
                source={source} />

            <Content request={request} />

            <Footer source={source} />

        </>
    )
}

export default Base;