import { useEffect } from "react";
import { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import Slide from "react-reveal/Slide";
import { Zoom } from "react-reveal";
import Particles from "./particles";
import MetaTags from 'react-meta-tags';
import { Icon } from "@iconify/react";
import DocumentMeta from "react-document-meta";
function BlogView({ params, request, baseURL }) {
  const [content, setContent] = useState("");
  const [searched, setSearched] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
  }, [content]);

  if (content == "")
    request(
      "GET",
      `blog/get/${params.slug}`,
      (response) => {
        setContent(response.data);
        setSearched(true);
    
      },
      (error) => {
        setSearched(true);
      },
      "Obteniendo datos"
    );

  if (!searched)
    return (
      <div className="content row">
        <ThreeCircles
          height="70"
          width="70"
          color="#ff6633"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </div>
    );
  return (
    <section className="content row_change wrap center">
      <MetaTags>
        <title>Onexo | {content.title}</title>
        <meta itemprop="name" content={content.title} />
        <meta name="og:title" content={content.title} />
        <meta name="description" content={content.descripcion} />
        <meta property="og:type" content="article" />
        <meta property="og:image:width" content="436" />
        <meta property="og:image:height" content="228" />
        <meta property="og:image" content={`${baseURL}${content.mini_img}`} />
    </MetaTags>
      <Slide top>
        <div className="row_change center vcenter cabecera">
          <div className="cover_cabecera"></div>
          <img
            src={`${baseURL}${content.img}`}
            alt={content.title}
            className=""
            width={"100%"}
          />
        </div>
      </Slide>

      <div className="row_chenge pad30 mw1120px wrap">
        <h1 className="title f33 text_orange row_chenge">{content.title}</h1>
        <div className="row_change vcenter ">
          <figure className="figure_autor_img margin10">
            <img
              src={`${baseURL}${content.autor_img}`}
              alt={content.autor}
              className="autor_img"
            />
          </figure>

          <div className="row">
            <div className="row wrap">
              <div className="row_change nunito">
                Por {content.autor}
                {windowDimensions.width > 768?<span className="italic text_orange">{" - "}{content.cargo}</span>:""}
              </div>
              <div className="row_change">
                <a href={`https://${content.linkedin}`} target="blank" ><Icon icon="logos:linkedin" /></a>
              </div>
              <div className="row_change nunito text_orange">
                {content.created}
              </div>
              
            </div>
          </div>
        </div>

        <div
          id="content"
          className="nunito p10 margintb15 justify"
          dangerouslySetInnerHTML={{ __html: content.content }}
        ></div>
      </div>
    </section>
  );
}
export default BlogView;
