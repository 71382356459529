import Base from "./Base";
import React, { useState, useEffect } from "react";
import useCollapse from "react-collapsed";
import "./../static/css/Home.css";
import MetaTags from 'react-meta-tags';
import { Collapse } from "react-collapse";
import onexo_inicio_nosotros from "./../static/images/onexo_inicio_nosotros.png";
import onexo_servicios_web from "./../static/images/onexo_servicios_web.webp";
import onexo_servicios_apps from "./../static/images/onexo_servicios_apps.webp";
import onexo_bp_continuidad from "./../static/images/onexo_bp_continuidad.png";
import onexo_servicios_ui from "./../static/images/onexo_servicios_ui.webp";
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faWalkieTalkie } from "@fortawesome/free-solid-svg-icons";
import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { faCarOn } from "@fortawesome/free-solid-svg-icons";
import { faGaugeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Banner() {
  return (
    <section className="banner row" id="banner">
      <div className="row wrap mw1000px pad50">
        <div className="row jcleft">
          <h1 className="exoB text_white main_text">
            El camino al <br /> éxito es digital.
          </h1>
        </div>
        <div className="row jcleft">
          <h3 className="nunito text_white second_text">
            Nosotros somos la guía.
          </h3>
        </div>
      </div>
    </section>
  );
}

function Nosostros() {
  return (
    <section className="nosotros" id="nosotros">
      <MetaTags>
        <title>Onexo | El camino al éxito es digital</title>
        <meta itemprop="name" content="Onexo | El camino al éxito es digital" />
        <meta name="og:title" content="Onexo | El camino al éxito es digital" />
        <meta name="description" content="Somos una empresa tecnológica que aterriza ideas de negocio en el mundo digital desarrollando portales web y aplicaciones móviles de la mano de nuestros expertos. Nos involucramos por completo en cada proyecto que abordamos. Encontramos el equilibrio entre estética y funcionalidad." />
        <meta
      name="og:image"
      content="/static/media/log.dd786eb183f32ae53d8b68bb7e5376df.svg"
    />
    <meta property="og:type"               content="article" />
    </MetaTags>
      <div className="row pad50 wrap">
        <div className="row mw600px jcleft wrap pad10">
          <Bounce>
            <h2 className="title">Nosotros</h2>
          </Bounce>
          <Slide left cascade>
            <div className="row tetx_normal p_normal">
              <p>
                Somos una empresa tecnológica que aterriza ideas de negocio en el
                mundo digital desarrollando portales web y aplicaciones móviles de
                la mano de nuestros expertos. Nos involucramos por completo en
                cada proyecto que abordamos.
              </p>
            </div>
            <div className="row tetx_normal p_normal">

              <p>
                Trabajando de forma colaborativa y con escucha, acompañamos de
                inicio a fin a nuestros clientes durante todo el proceso de
                creación para asegurarnos de cubrir cada requerimiento de
                funcionalidad y diseño.
              </p>

            </div>
          </Slide>
          <Link to="/nosotros" className="btn margintb15" >Leer más</Link>
        </div>
        <div className="row mw400px">
          <img
            src={onexo_inicio_nosotros}
            alt="onexo_inicio_nosotros"
            width={400}
            className="box_left"
            title="work"
          />
        </div>
      </div>
      <div className="row"></div>
    </section>
  );
}

function Servicios() {
  const [serviceActive, setServiceActive] = useState("web")
  function onClick(e) {
    var id = e.currentTarget.id
    if (serviceActive != id)
      setServiceActive(id)
  }
  useEffect(() => {
    var btns = document.getElementsByClassName("btn_servicio")
    var infoCards = document.getElementsByClassName("service_info")
    for (var btn in btns) {
      if (btns[btn].id)
        btns[btn].classList.remove("active")
      if (btns[btn].id == serviceActive)
        btns[btn].classList.add("active")
    }
  });
  const Service = ({ title, description, image, visible, id }) => {
    useEffect(() => {
      if (serviceActive == id)
        visible = true
      else
        visible = false
    });

    return (
      <div className={serviceActive == id ? "row service_info wrap visible" : "row service_info wrap"} itemID={id} >
        <div className="row mw400px wrap">
          <div className="row jcleft">
            <Bounce>
              <h2 className="title">{title}</h2>
            </Bounce>

          </div>
          <div className="row tetx_normal margintb15">
          <Slide bottom>
            <p>
              {description}
            </p>
            </Slide>
          </div>
          <div className="row jcright margintb15">
            <div className="short_margin_right"></div>
          </div>
        </div>
        <div className="row img_service_home marlr40">
          <img
            src={image}
            alt={image}
            height="100%"
            title="chief"
          />
        </div>
      </div>
    )
  }


  return (
    <section className="servicios pad50" id="servicios">
      <div className="row pad50 wrap">
        <div className="row wrap">
          <Bounce>
            <h2 className="title">Servicios</h2>
          </Bounce>


        </div>
      </div>
      <div className="row pad10">
        <div className="btn_servicio active" onClick={onClick} id="web" >
          <div className="row">
            <span className="icon">
              <FontAwesomeIcon icon={faDesktop} />
            </span>
            <span className="hid"> Desarrollo web</span>
          </div>
        </div>

        <div className="btn_servicio " onClick={onClick} id="movil">
          <div className="row">
            <span className="icon">
              <FontAwesomeIcon icon={faMobileScreen} />
            </span>
            <span className="hid"> Desarrollo móvil</span>
          </div>
        </div>
        <div className="btn_servicio " onClick={onClick} id="diseño">
          <div className="row">
            <span className="icon">
              <FontAwesomeIcon icon={faPalette} />
            </span>
            <span className="hid"> Diseño de interfaces de usuario</span>
          </div>
        </div>
      </div>
      <div className="row pad50 wrap">
        <Service title={"Desarrollo web"} description={"Creamos sitios y aplicaciones web, siempre a la par de las tendencias tecnológicas. Nuestro proceso de desarrollo se basa en codificar pensando en el usuario, lo que, junto con el diseño y la optimización, garantizará la mejor experiencia para sus clientes y el máximo éxito para su empresa."} image={onexo_servicios_web} visible={true} id={"web"} />
        <Service title={"Desarrollo móvil"} description={"En la era del dispositivo móvil, donde hay una necesidad constante de estar conectado e informado, estamos preparados para ayudarle a desarrollar aplicaciones que faciliten la comunicación y la movilidad con sus clientes y colaboradores. Nuestro equipo crea aplicaciones para cualquier dispositivo y comprende en su totalidad las necesidades que la aplicación puede abordar."} image={onexo_servicios_apps} visible={false} id={"movil"} />
        <Service title={"Diseño de interfaces de usuario"} description={"Estudiamos a fondo el comportamiento del usuario para recopilar criterios que sienten las bases de una interfaz fluida, funcional e intuitiva. Alcance sus metas empresariales con una experiencia ideal para el usuario."} image={onexo_servicios_ui} visible={false} id={"diseño"} />
      </div>
    </section>
  );
}

function Digitalizamos() {
  const [cardA, setCardA] = useState(0);
  const Card = ({ number, title, description, active }) => {
    const [isExpanded, setExpanded] = React.useState(active);
    const { getToggleProps, getCollapseProps } = useCollapse({
      isExpanded,
    });
    React.useEffect(() => {
      setExpanded(active);
    }, [active, setExpanded]);

    return (
      <div className="row card wrap">
        <div
          className="row card_header"
          {...getToggleProps({
            style: { display: "flex" },
            onClick: () => {
              setExpanded((x) => !x);
            },
          })}
        >
          <div className="number_card"> {number} </div>

          <div className={isExpanded ? "name active" : "name"}>{title} </div>
          <div className={isExpanded ? "arrow active" : "arrow"}>
            {isExpanded ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>
        </div>

        <div
          {...getCollapseProps()}
          className={
            active ? "tetx_normal visible row" : "tetx_normal row hidden"
          }
        >
          <p className="text_card">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <section className="digitalizamos pad50 row wrap" id="digitalizamos">
      <div className="row wrap mw500px jcleft padtb50">
        <div className="row subtitle text_white jcleft">
          Digitalizamos su negocio planeando
        </div>
        <div className="row subtitle text_orange jcleft">
          un camino de buenas prácticas.
        </div>
        <div className="row wrap margintb15 mw500px cards">
          <Card
            number={"01"}
            title={"Continuidad de proyectos"}
            description={
              "Sabemos que siempre pueden surgir más necesidades y que la inversión tecnológica es muy importante, es por eso que ofrecemos la continuidad para analizar proyectos pasados que necesiten actualizaciones y/o mejoras para asegurar su funcionamiento y evolucionar."
            }
            active={true}
          />
          <Card
            number={"02"}
            title={"Capacitación"}
            description={
              "Al finalizar cualquier proyecto ofrecemos materiales informativos y capacitación, logrando una transferencia de conocimiento precisa que habilita a los usuarios para dominar el funcionamiento de su aplicación móvil/web o página web."
            }
            active={false}
          />
        </div>
      </div>
      <div className="row wrap mw600px">
        <img
          src={onexo_bp_continuidad}
          alt="onexo_bp_continuidad"
          className="row"
          title="continuidad"
        />
      </div>
    </section>
  );
}

function CasosDeExito() {
  return (
    <section className="casosDeExito row wrap" id="casos">
      <div className="row">
        <Bounce>
          <div className="title">Casos de éxito</div>
        </Bounce>

      </div>
      <div className="row mw600px margintb15">
        <div className="subtitle text_white">
          Conozca a los viajeros que están confiando su transición digital en
          nuestras manos.
        </div>
      </div>
      <div className="row margintb15">
      <Link to="/casos_de_exito" className="btn_servicio active margintb15" >Quiero conocerlos</Link>
      </div>
    </section>
  );
}

function Boosters() {
  return (
    <section className="boosters row wrap" id="boosters">
      <div className="row">
        <Bounce>
          <div className="title padlr50">
            <span className="cursive">Boosters</span> de servicio
          </div>
        </Bounce>

      </div>
      <div className="row padtb30 wrap mw1140px">
        <div className="row wrap_mw">
          <div className="card_boost b pad30 row wrap">
            <p className="text_white bost_title nunito row jcleft">
              Atención personalizada
            </p>
            <div className="row padtb20 jcleft wrap">
            <Slide bottom>
              <div className="row subtitle text_white mw70">
                Asistencia guiada durante todo el proyecto.
              </div>
              </Slide>
              <div className="row icon_boost text_white">
                <FontAwesomeIcon icon={faWalkieTalkie} />
              </div>
            </div>
          </div>
          <div className="card_boost o pad30 row wrap">
            <p className="text_white bost_title nunito row jcleft">
              Proyectos orientados a necesidades.
            </p>
            <div className="row padtb20 jcleft wrap">
            <Slide bottom>
              <div className="row subtitle text_white mw70">
                Trazamos la mejor ruta al éxito de su negocio.
              </div>
             </Slide>
              <div className="row icon_boost text_white jcright">
                <FontAwesomeIcon icon={faRoute} />
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="card_boost c row pad30 wrap jcright">
            <p className="text_white bost_title nunito row jcleft">
              Soporte eficiente
            </p>
            <Slide right>
            <div className="row subtitle text_white text_c mw400px">
              Tiempo de reacción veloz como pits de Formula 1.
            </div>
            </Slide>
          </div>
        </div>
        <div className="row wrap_mw">
          <div className="card_boost d pad30">
            <p className="text_white bost_title nunito row jcleft">
            Normativas en regla
            </p>
            <Slide bottom>
            <div className="row subtitle text_white text_t mw400px padtb50">
              Circule por la carretera digital sin infracciones.
            </div>
           </Slide>
          </div>
          <div className="card_boost e pad30">
            <p className="text_white bost_title nunito row jcleft">
            Reducción de tiempos de espera
            </p>
            <Slide bottom>
            <div className="row subtitle text_white text_t mw400px padtb50">
              Alcancé un nuevo récord en clientes satisfechos.
            </div>
           </Slide>
          </div>
        </div>
        <div className="row ">
          <div className="card_boost f row pad30 wrap jcright">
            <p className="text_white bost_title nunito row jcleft">
              Visibilidad digital garantizada
            </p>
            <Slide right>
            <div className="row subtitle text_white text_c mw400px">
              Un nuevo destino en la órbita digital.
            </div>
            </Slide>
          </div>
        </div>
        <div className="row wrap_mw">
          <div className="card_boost b pad30 row wrap">
            <p className="text_white bost_title nunito row jcleft">
              Reduzca gastos y uso de papel
            </p>
            <div className="row padtb20 jcleft wrap">
            <Slide bottom> 
              <div className="row subtitle text_white mw70">
                El futuro de la conducción
                de su empresa.
              </div>
              </Slide>
              <div className="row icon_boost text_white">
                <FontAwesomeIcon icon={faCarOn} />
              </div>
            </div>
          </div>
          <div className="card_boost o pad30 row wrap">
            <p className="text_white bost_title nunito row jcleft">
              Proyectos orientados a necesidades
            </p>
            <div className="row padtb20 jcleft wrap">
            <Slide bottom>
              <div className="row subtitle text_white mw70">
                Su empresa con
                performance inigualable.
              </div>
              </Slide>
              <div className="row icon_boost text_white jcright">
                <FontAwesomeIcon icon={faGaugeHigh} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const Content = ({onLoad}) => {
  return (
    <section className="content" onLoad={onLoad}>
      <Banner />
      <Nosostros />
      <Servicios />
      <Digitalizamos />
      <CasosDeExito />
      <Boosters />
    </section>
  );
};

export default function Home() {
  return <Base Content={Content} source={"Home"} />;
}
